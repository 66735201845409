@font-face {
    font-family: 'im_fell_dw_picaitalic';

    src: url("./design/fonts/evermore/imfepiit28p-webfont.woff2") format('woff2'),
        url("./design/fonts/evermore/imfepiit28p-webfont.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'pistilli-roman';

    src: url("./design/fonts/eras_tour/pistilli-roman-webfont.woff") format('woff'),
        url("./design/fonts/eras_tour/pistilli-roman-webfont.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pinyon_script';

    src: url("./design/fonts/lover/pinyonscript-regular-webfont.woff2") format('woff2'),
        url("./design/fonts/lover/pinyonscript-regular-webfont.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'chomskyregular';
    src: url('./design/fonts/reputation/chomsky-webfont.woff2') format('woff2'),
        url('./design/fonts/reputation/chomsky-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.fade-in-lyric {
    transition: background-color 0.75s linear;
}

.fade-out-lyric {
    opacity: 0;
    background-color: #C2DFFF;
}